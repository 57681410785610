<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import {
    UPDATE_MERCHANT_ENTITY_DETAILS,
    FETCH_MERCHANT_ENTITY_DETAILS,
    UPDATE_MERCHANT_LOGO
} from "@/state/actions.type";

import {
  UPLOAD_PAGE_IMAGE_IN_STORE
} from '@/state/mutations.type';

import cloneDeep from "lodash.clonedeep";
import Common from '../../../helpers/Common';

export default {
    components: {
        VuePhoneNumberInput
    },
    data() {
        return {
            form: {
                brand_name: "",
                support_email: "",
                support_phone: "",
                website_url: "",
                business_details: "",
            },
            phoneNumber: null
        }
    },
    computed: {
        merchantDetails() {
            return this.$store.state.merchant.entityDetails;
        },
    },
    created() {
        this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`).then(this.resetProfileDetails);
    },
    methods: {
        saveProfile() {
            if(this.phoneNumber && this.phoneNumber.formattedNumber && !this.phoneNumber.isValid) {
                this.$notify({
                    type: "error",
                    text: "Phone number is Invalid",
                    closeOnClick: true,
                });
                return false;
            }

            const formData = cloneDeep(this.form);
            formData.support_phone = this.phoneNumber && this.phoneNumber.formattedNumber ? this.phoneNumber.formattedNumber : "";
            this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, formData)
            .then(() => {
                this.$notify({
                    type: "success",
                    text: "Account details saved successfully.",
                    closeOnClick: true,
                });
                this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`).then(this.resetProfileDetails);
            })
        },
        resetProfileDetails() {
            this.form = {
                brand_name: this.merchantDetails.brand_name,
                support_email: this.merchantDetails.support_email,
                support_phone: "",
                website_url: this.merchantDetails.website_url,
                business_details: this.merchantDetails.business_details,
            };

            if(this.merchantDetails.support_phone && isValidPhoneNumber(this.merchantDetails.support_phone)) {
                const phoneNumber = parsePhoneNumber(this.merchantDetails.support_phone);

                if(phoneNumber) {
                    this.phoneNumber = {
                        "countryCode": phoneNumber.country,
                        "isValid": phoneNumber.isValid(),
                        "phoneNumber": phoneNumber.nationalNumber,
                        "countryCallingCode": phoneNumber.countryCallingCode,
                        "formattedNumber": phoneNumber.number,
                        "nationalNumber": phoneNumber.nationalNumber
                    }

                    this.form.support_phone = phoneNumber.nationalNumber;
                }
            }
        },
        detailsUpdated() {
            return (
                this.merchantDetails.brand_name !== this.form.brand_name ||
                this.merchantDetails.support_email !== this.form.support_email ||
                (this.phoneNumber && (this.merchantDetails.support_phone !== this.phoneNumber.formattedNumber)) ||
                this.merchantDetails.website_url !== this.form.website_url || this.merchantDetails.business_details !== this.form.business_details
            );
        },
        updatePhoneNumber(e) {
            this.phoneNumber = e;
            this.form.support_phone = e.phoneNumber;
        },
        changeLogo(url) {
            this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, {logo: url}).then((response) => {
                this.$notify({
                    type: 'success',
                    text: 'Logo Updated Successfully',
                    closeOnClick: true,
                });
                this.$store.dispatch(`merchant/${UPDATE_MERCHANT_LOGO}`, response.logo);
            })
            .catch((error) => {
                this.$notify({
                    type: 'error',
                    text: error.message,
                    closeOnClick: true,
                });
                console.log("error");
            });
        },
        uploadFile() {
            let fileList = event.target.files;

            if (!fileList.length) return;

            const formData = new FormData();

            if (!Common.fileValdation(fileList[0])) {
                return;
            }

            formData.append('file', fileList[0], fileList[0].name);

            this.$store.dispatch(`paymentPages/${UPLOAD_PAGE_IMAGE_IN_STORE}`, formData).then((response) => {
                this.changeLogo(response.link);
            })
            .catch((error) => {
                console.log(error);
                this.$notify({
                    type: 'error',
                    text: error.status_message,
                    closeOnClick: true,
                });
            });
        }
    }
}
</script>

<template>
    <div class="d-flex" style="gap: 25px;">
        <div class="main-section">
            <div class="section-header">
                <p class="section-title">Business Information</p>
                <p class="section-text">Update your company details and brand logo here.</p>
            </div>

            <form class="profile-form">
                <b-form-group
                    id="field-brand-name"
                    label="Brand Name"
                    label-for="brand-name"
                    valid-feedback="Thank you!"
                >
                    <b-form-input id="brand-name" v-model="form.brand_name" trim></b-form-input>
                </b-form-group>

                <b-form-group
                    id="field-support-email"
                    label="Company Support Email"
                    label-for="email"
                >
                    <b-input-group class="bg-transparent">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="black" stroke-opacity="0.7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="black" stroke-opacity="0.7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <b-form-input id="support-email" v-model="form.support_email" class="border-0" style="outline: none; box-shadow: none;" trim></b-form-input>
                    </b-input-group>
                </b-form-group>

                <b-form-group
                    id="field-support-contact-number"
                    label="Support Contact Number"
                    label-for="support-contact-number"
                    valid-feedback="Thank you!"
                >
                    <VuePhoneNumberInput
                        :value="form.support_phone"
                        @update="updatePhoneNumber($event)"
                        :default-country-code="phoneNumber && phoneNumber.countryCode ? phoneNumber.countryCode : $store.state.auth.currentUser.country"
                        :preferred-countries="['IN', 'ID', 'MY', 'PH', 'SG', 'KR', 'TH', 'VN']"
                    />
                </b-form-group>

                <b-form-group
                    id="field-website-url"
                    label="URL"
                    label-for="website-url"
                    valid-feedback="Thank you!"
                >
                    <b-form-input id="website-url" type="url" v-model="form.website_url" trim></b-form-input>
                </b-form-group>
                
                <b-form-group
                    id="field-website-business-details"
                    label="Business Details"
                    label-for="website-business-details"
                    valid-feedback="Thank you!"
                >
                    <b-form-textarea id="website-business-details" type="text" v-model="form.business_details" trim rows="5" max-rows="6" placeholder="Address Line 1, Address Line 2, City, State, Zip-000001"></b-form-textarea>
                </b-form-group>


                <div v-if="detailsUpdated()" style="padding-top: 25px; gap: 12px; border-top: 1px solid rgba(220, 218, 213, 0.50);" class="d-flex justify-content-end">
                    <b-btn variant="primary" @click="resetProfileDetails" class="bg-white text-dark cancel">Cancel</b-btn>
                    <b-btn @click="saveProfile" class="save">Save</b-btn>
                </div>
            </form>
        </div>

        <div class="profile-section">
            <p class="section-title">Brand Logo</p>
            <p class="section-text">Change the company logo here.</p>

            <b-img :src="merchantDetails.logo" v-bind="{ blank: !merchantDetails.logo, blankColor: '#DCDAD5', width: 221, height: 221 }" rounded="circle" alt="Circle image" style="margin: 40px 33px 57px;"></b-img>
            <!-- <div style="margin: 40px 33px 57px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="221" height="221" viewBox="0 0 221 221" fill="none">
                    <circle cx="110.5" cy="110.5" r="110.5" fill="#DCDAD5"/>
                </svg>
            </div> -->

            <input type='file' ref="imageUpload" class="d-none" accept=".png, .jpg, .jpeg" @change="uploadFile" />
            <b-btn variant="primary" @click="$refs.imageUpload.click()" class="w-100 h-60px mb-25px">Change Photo</b-btn>
            <b-btn variant="white" @click="changeLogo('')" class="w-100 h-60px border-black">Remove</b-btn>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main-section {
    width: 704px;
}
.profile-section {
    border-radius: 15px;
    border: 1px solid rgba(220, 218, 213, 0.50);
    background: rgba(255, 255, 255, 0.30);
    width: 337px;
    height: 570px;
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    padding: 25px;
}
.profile-form {
    padding: 25px;
}
.form-control {
    padding: 12px 16px;
    height: 44px;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
}
.input-group {
    padding: 12px 16px;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
    * {
        height: 24px;
    }
}
::v-deep .form-group > label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 15px !important;
}
.input-group {
    gap: 8px;
    background: rgba(220, 218, 213, 0.30);
}
.form-group {
    margin-bottom: 25px;
}
::placeholder {
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 325;
    line-height: 24px;
}
</style>