<script>
import {
  FETCH_MERCHANT_ENTITY_DETAILS,
  UPDATE_SECRET_KEY_BY_FORM
} from "@/state/actions.type";

export default {
  page: {
    title: "API Keys",
  },

  data() {
    return {
      showPortOneKey: false,
      showSecretKey: false,
      showEditSecretKeyModal: false
    }
  },

  created() {
    this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`);
  },

  methods: {
    updateSecretKey() {
      this.$store.dispatch(`webhook/${UPDATE_SECRET_KEY_BY_FORM}`, {secretKeyData: this.$store.state.merchant.entityDetails})
      .then(() => {
        this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`);
        this.toggleEditSecreatKey();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    toggleEditSecreatKey() {
      this.showEditSecretKeyModal = !this.showEditSecretKeyModal;
    },
    onCopyChaiPayKey() {
      this.$notify({
        type: 'success',
        text: this.$t('views.integration.api-settings.general.client_key_copy_success'),
        closeOnClick: true,
      });
    },
  }
};
</script>

<template>
  <div>
    <div class="main-section">
      <div class="section-header">
        <p class="section-title">API Keys</p>
        <p class="section-text">Use your PortOne account keys for APIs & Integrations.</p>
      </div>
    </div>

    <div style="padding: 25px;">
      <b-form-group
        id="field-portone-key"
        label="PortOne Key"
        label-for="portone-key"
      >
      <span 
        v-clipboard:success="onCopyChaiPayKey"
        v-clipboard:copy="$store.state.merchant.entityDetails.iamportKey"
        >
        <b-input-group>
          <template #append>
            <i class="fas my-auto btn p-0" @click="() => showPortOneKey = !showPortOneKey" :class="{ 'fa-eye-slash': showPortOneKey, 'fa-eye': !showPortOneKey }"></i>
          </template>
          <b-form-input
            id="portone-key"
            :type="showPortOneKey ? 'text' : 'password'"
            :value="$store.state.merchant.entityDetails.iamportKey"
            style="cursor: copy;"
            readonly
          ></b-form-input>
        </b-input-group>
      </span>
      </b-form-group>
  
      <b-form-group
        id="field-portone-secret-key"
        label="PortOne Secret Key"
        label-for="portone-secret-key"
      >
      <span 
        v-clipboard:success="onCopyChaiPayKey"
        v-clipboard:copy="$store.state.merchant.entityDetails.iamportSecret"
        >
        <b-input-group>
          <template #append>
            <i class="fas my-auto btn p-0" @click="() => showSecretKey = !showSecretKey" :class="{ 'fa-eye-slash': showSecretKey, 'fa-eye': !showSecretKey }"></i>
          </template>
          <b-form-input
            id="portone-secret-key"
            :type="showSecretKey ? 'text' : 'password'"
            :value="$store.state.merchant.entityDetails.iamportSecret"
            style="cursor: copy;"
            readonly
          ></b-form-input>
        </b-input-group>
      </span>
      </b-form-group>

      <b-btn
        v-if="!$store.state.auth.currentUser.roles.includes('sub-admin')"
        variant="primary"
        class="btn-padding float-right"
        @click="toggleEditSecreatKey"
      >
        Reset Secret Key
        <!-- {{ $t("common.reset") }} -->
      </b-btn>
    </div>

    <!-- Edit Webhook Modal -->
    <b-modal
      id="edit-secretkey-modal"
      v-model="showEditSecretKeyModal"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div class="d-flex justify-content-between" style="padding-bottom: 25px;">
        <!-- Emulate built in modal header close button action -->
        <h5 class="font-20px text-black">Reset Secret Key Warning</h5>
        <img @click="toggleEditSecreatKey" class="modal-close" src="@/assets/images/close.svg" alt="close" />
      </div>
      <h5 style="color:#FF3131; font-weight: 350; margin-bottom: 25px;">
        {{ $t('views.integration.api-settings.general.resetting_msg') }}
      </h5>
      <div style="gap: 12px;" class="d-flex justify-content-end">
        <b-btn variant="primary" @click="toggleEditSecreatKey" class="bg-white text-dark cancel">Cancel</b-btn>
        <b-btn variant="primary" @click="updateSecretKey" class="save">Save</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  padding: 6px 16px;
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
}
::v-deep .form-group {
  margin-bottom: 25px;

  label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 15px !important;
  }
}
.form-control {
  background: #FFF;
  border: none;
  height: 24px;
  padding: auto;
}
::v-deep .modal-body {
  padding: 35px;
}

.modal-close {
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: rgba(220, 218, 213, 0.30);
  }
}
</style>
