<script>

import {
  required,
  email,
  // minLength
} from "vuelidate/lib/validators";
import SwitchInput from '../../../components/switch-input.vue';
import VuePhoneNumberInput from "vue-phone-number-input";
import {
  CREATE_SUB_USER,
  UPDATE_SUB_USER_DETAILS,
  FETCH_DETAILS_FOR_ALL_SUB_USERS,
} from "@/state/actions.type";
import Multiselect from 'vue-multiselect';
import * as cloneDeep from "lodash.clonedeep";
import {rolesPermissionMapping} from "../../../assets/rbac-roles";
// import DownloadBtn from '../../../components/download-btn.vue';

export default {
  page: {
    title: "Account Settings",
  },

  components: {
    Multiselect,
    VuePhoneNumberInput,
    SwitchInput,
    // DownloadBtn,
  },

  data() {
    return {
      // TODO: Get this dynamically via API
      currentSubUserDetail: {
        name: "",
        phone_number: "",
        email_address: "",
        is_active: false,
        is_global_user: true,
        roles: [],
      },
      phoneNumber: null,
      addUserFormSubmitted: false,
      showAddUserModal: false,
      isEdit: false,
      fields: [
        { key: "select", sortable: false, label: "" },
        { key: "name", sortable: true, tooltip: "accounts.table.tooltip.name", label: "accounts.table.fields.name" },
        { key: "email_address", sortable: true, tooltip: "accounts.table.tooltip.email_address", label: "accounts.table.fields.email_address" },
        { key: "phone_number", sortable: true, tooltip: "accounts.table.tooltip.phone_number", label: "accounts.table.fields.phone_number" },
        { key: "roles", sortable: false, tooltip: "accounts.table.tooltip.roles", label: "accounts.table.fields.roles" },
        { key: "is_active", sortable: true, tooltip: "accounts.table.tooltip.is_active", label: "accounts.table.fields.is_active" },
        { key: "is_2fa_enabled", sortable: true, tooltip: "accounts.table.tooltip.is_2fa_enabled", label: "accounts.table.fields.is_2fa_enabled" },
        { key: "options", sortable: false, label: "", stickyColumn: true },
      ],
    };
  },

  created() {
    this.$store.dispatch('notification/clear');
    this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
  },

  computed: {
    subUserDetails() {
      return this.$store.state.accounts.subUserData;
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    title() {
      return this.$t('views.accounts.accounts');
    },

    items() {
      return [{
        text: 'PortOne',
        active: true,
      },
      {
        text: this.$t('views.accounts.accounts'),
        active: true,
      }];
    },
    subUserUuid() {
      return this.$store.state.auth.currentUser.subUserUuid;
    },
    userRoles() {
      return this.$store.state.auth.currentUser.roles;
    },

    rolesList() {
      let roles = [
        'payment-user',
        'payment-admin',
        'payment-links-user',
        'payouts-admin',
        'payouts-user',
        'payouts-verify-user',
        'payouts-approve-user',
        'payout-channel-configurator',
        'payment-channel-configurator',
        'payment-refund-user',
        'payment-pages-user',
        'invoice-user',
        'recon-user',
        'recon-admin',
        'recon-configurator'
      ]
      if(this.userRoles.includes('admin')) {
        roles = [...roles, 'sub-admin'];
      }
      return roles;
    }
  },

  validations: {
    currentSubUserDetail: {
      name: { required },
      email_address: { required, email },
      phone_number: { required },
    },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    addSubUser(e) {
      this.addUserFormSubmitted = true;

      // stop here if form is invalid
      this.$v.currentSubUserDetail.$touch();
      if (this.$v.currentSubUserDetail.$invalid) {
        return;
      }

      if(!this.phoneNumber.isValid) {
        this.$notify({
          type: "error",
          text: "Phone number is Invalid",
          closeOnClick: true,
        });
        return false;
      }

      let request;
      const formData = cloneDeep(this.currentSubUserDetail);
      formData.phone_number = this.phoneNumber.formattedNumber;

      if(this.isEdit) {
        request = this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.currentSubUserDetail.uuid, subUserDetails: formData})
      } else {
        request = this.$store.dispatch(`accounts/${CREATE_SUB_USER}`, formData)
      }

      request.then(() => {
        // Added new user
        this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`)
        this.isEdit = false;
        this.showAddUserModal = false;
      })
      .catch((error) => {
        // Added new user
        console.log(error);
        this.isEdit = false;
        this.showAddUserModal = false;
        const errorObject = JSON.parse(error.message)
        this.$notify({
            type: "error",
            text: errorObject.message,
            closeOnClick: true,
        });
      })
      .finally(() => {
        this.currentSubUserDetail = {
          name: "",
          phone_number: "",
          email_address: "",
          is_active: false,
          is_global_user: true,
          roles: [],
        };
        this.addUserFormSubmitted = false;
      });
    },

    openAddUserModal() {
      this.resetNotificationError();
      this.showAddUserModal = true;
    },

    hideAddUserModal() {
      this.currentSubUserDetail = {
        name: "",
        phone_number: "",
        email_address: "",
        is_active: false,
        is_global_user: true,
        roles: [],
      };
      this.addUserFormSubmitted = false;
      this.showAddUserModal = false;
      this.isEdit = false;
    },

    openEditUserModal(userDetails) {
      if(this.editAccess(userDetails)) {
        this.currentSubUserDetail = cloneDeep(userDetails);
        this.resetNotificationError();
        this.showAddUserModal = true;
        this.isEdit = true;
      }
    },

    resetNotificationError() {
      this.$store.dispatch('notification/clear');
    },

    toggleSwitchForAdd(val) {
      this.currentSubUserDetail.is_active = val;
    },

    toggleSwitchForGlobalUser(val) {
      this.currentSubUserDetail.is_global_user = val;
    },
    // TODO: Get this dynamically via API
    userPermissionsByRole(role) {
      return rolesPermissionMapping[role];
    },

    editAccess(item) {
      if((item.uuid === this.subUserUuid) && (this.userRoles.includes('admin') || this.userRoles.includes('sub-admin'))) {
        return true;
      }

      if(this.userRoles.includes('admin') && !item.roles.includes('admin')) {
        return true;
      }

      if(this.userRoles.includes('sub-admin') && !item.roles.includes('admin') && !item.roles.includes('sub-admin')) {
        return true;
      }

      return false;
    },
    downloadExcel() {
            
    },
    updatePhoneNumber(e) {
      this.phoneNumber = e;
      this.currentSubUserDetail.phone_number = e.nationalNumber ? e.nationalNumber : e.phoneNumber;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
    <div>
        <b-alert
          variant="danger"
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
          @dismissed="resetNotificationError"
        >{{notification.message}}</b-alert>
    
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="section-header">
                <div class="d-flex justify-content-between flex-wrap" style="gap: 10px;">
                    <div>
                        <p class="section-title">Team Management</p>
                        <p class="section-text">Manage your team members and their account permissions here.</p>
                    </div>
                    <div class="d-flex" style="gap: 10px;">
                        <!-- <download-btn variant="white border-black" @download-button-clicked="downloadExcel()"/> -->
                        <b-btn
                            variant="primary"
                            style="padding: 12px 16px;"
                            @click="openAddUserModal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M6 12H18M12 18V6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{ $t('views.accounts.invite_new_user') }}
                        </b-btn>
                    </div>
                </div>
                <div style="background: rgba(220, 218, 213, 0.70); height: 1px; margin-top: 25px; margin-bottom: 25px;"></div>
                <!-- <p class="section-text m-0" style="line-height: normal;">Selected <span class="text-chai" style="font-weight: 400;">10 Rows</span> out of 100. <button class="text-chai btn p-0 font-14px" style="font-weight: 400; text-decoration: underline;">DELETE</button> all at once here.</p> -->
            </div>

            <b-table
              class="table-custom"
              :items="subUserDetails"
              :fields="fields"
              responsive="sm"
              sticky-header="500px"
              show-empty
              :empty-text="$t('common.no-records')"
              hover
            >
              <template v-slot:head()="data">
                  <!-- v-model="selectAvailableInPage" -->
                  <!-- @change="selectAllInPage" -->
                  <!-- <b-form-checkbox
                      v-if="data.column === 'select'"
                      id="select_all"
                      name="select_all"
                      :value="true"
                      :unchecked-value="false"
                  ></b-form-checkbox> -->
                  <!-- v-else -->
                  <span
                      v-b-tooltip.hover.top
                      :title="$t(data.field.tooltip)"
                  >
                      {{ $t(data.label) }}
                  </span>
              </template>

              <!-- v-model="selectAvailableInPage" -->
              <!-- <template v-slot:cell(select)="row">
                  <b-form-checkbox
                    id="select_all"
                    name="select_all"
                    :value="true"
                    :unchecked-value="false"
                    @change="selectMerchant(row.item)"
                  ></b-form-checkbox>
              </template> -->

              <template v-slot:cell(options)="row">
                <b-btn v-if="editAccess(row.item)" @click="openEditUserModal(row.item)" class="p-0" variant="white">
                  <img src="@/assets/images/flags/edit.svg" alt="Edit" />
                </b-btn>

                <!-- <b-btn v-if="editAccess(row.item)" @click="openEditUserModal(row.item)" class="p-0 ml-3" variant="white">
                  <img src="@/assets/images/flags/delete.svg" alt="Delete" />
                </b-btn> -->
              </template>

              <template v-slot:cell(is_active)="row">
                  <span class="badge-active" v-if="row.value">{{ $t("accounts.active") }}</span>
                  <span class="badge-inactive" v-else>{{ $t("accounts.inactive") }}</span>
              </template>

              <template v-slot:cell(is_2fa_enabled)="row">
                <p class="m-0 text-chai" v-if="row.value">{{ $t("accounts.enabled") }}</p>
                <p class="m-0" style="color: rgba(0, 0, 0, 0.50);" v-else>{{ $t("accounts.not_enabled") }}</p>
              </template>

              <template v-slot:cell(roles)="row">
                  <!-- <div
                  v-for="role in row.value"
                  :key="role"
                  class="badge badge-secondary mr-1 font-size-14"
                  >{{ role }}</div> -->
                  <div
                    v-for="role in row.value"
                    :key="role"
                    class="badge badge-secondary mr-1 font-size-14"
                  >
                    <span :id="role+row.item.email_address" >{{ role }}</span>
                    <b-tooltip :target="role+row.item.email_address" triggers="hover" placement="right">
                      <span>{{ 'Permissions : [ '  + userPermissionsByRole(role) + ' ]' }}</span>
                    </b-tooltip>
                  </div>
              </template>

            </b-table>
          </div>
        </div>
    
        <!-- Add New User Modal -->
        <b-modal
          id="add-user-modal"
          v-model="showAddUserModal"
          @hidden="hideAddUserModal"
          no-close-on-backdrop
          hide-footer
          hide-header
        >
          <div class="d-flex justify-content-between" style="padding-bottom: 25px;">
            <h5 class="font-20px text-black">{{ $t('accounts.add_user') }}</h5>
            <img @click="hideAddUserModal" class="modal-close" src="@/assets/images/close.svg" alt="close" />
          </div>

          <form @submit.prevent="addSubUser">
            <div class="form-group">
              <label for="name">{{ $t("accounts.table.fields.name") }}
                <!-- <span class="ml-1 text text-danger">*</span> -->
              </label>
              <input
                id="name"
                v-model="currentSubUserDetail.name"
                type="text"
                class="form-control"
                :placeholder="$t('accounts.placeholder.name')"
                :class="{ 'is-invalid': addUserFormSubmitted && $v.currentSubUserDetail.name.$error }"
              />
              <div v-if="addUserFormSubmitted && !$v.currentSubUserDetail.name.required" class="invalid-feedback">
                {{ $t('accounts.required.name') }}
              </div>
            </div>

            <div class="form-group">
              <label for="email_address">{{ $t("accounts.table.fields.email_address") }}
                <!-- <span class="ml-1 text text-danger">*</span> -->
              </label>
              <input
                id="email_address"
                v-model="currentSubUserDetail.email_address"
                type="email"
                class="form-control"
                :placeholder="$t('accounts.placeholder.email')"
                :class="{ 'is-invalid': addUserFormSubmitted && $v.currentSubUserDetail.email_address.$error }"
              />
              <div
                v-if="addUserFormSubmitted && !$v.currentSubUserDetail.email_address.required"
                class="invalid-feedback"
              >
                {{ $t('accounts.required.email') }}
              </div>
            </div>

            <div class="form-group">
              <label for="phone_number">{{ $t("accounts.table.fields.phone_number") }}
                <!-- <span class="ml-1 text text-danger">*</span> -->
              </label>
              <VuePhoneNumberInput
                :value="currentSubUserDetail.phone_number"
                @update="updatePhoneNumber($event)"
                :default-country-code="phoneNumber && phoneNumber.countryCode ? phoneNumber.countryCode : $store.state.auth.currentUser.country"
                :preferred-countries="['IN', 'ID', 'MY', 'PH', 'SG', 'KR', 'TH', 'VN']"
                :class="{ 'is-invalid': addUserFormSubmitted && $v.currentSubUserDetail.phone_number.$error }"
              />
              <div v-if="addUserFormSubmitted && !$v.currentSubUserDetail.phone_number.required" class="invalid-feedback">
                {{ $t('accounts.required.phone') }}
              </div>
            </div>

            <div class="form-group">
              <label>{{ $t("accounts.table.fields.roles") }}
                <!-- <span class="ml-1 text text-danger">*</span> -->
              </label>
              <multiselect
                :disabled="currentSubUserDetail.roles.includes('admin') || (!userRoles.includes('admin') && currentSubUserDetail.roles.includes('sub-admin'))"
                v-model="currentSubUserDetail.roles"
                :options="rolesList"
                :placeholder="$t('accounts.placeholder.roles')"
                size="sm"
                :searchable="true"
                :multiple="true"
                :taggable="true"
              ></multiselect>
            </div>

            <div class="d-flex justify-content-between">
              <div class="input-group" style="gap: 20px;">
                <label class="my-auto" for="is_active">{{ $t("accounts.activate_acc") }}
                  <!-- <span class="ml-1 text text-danger">*</span> -->
                </label>
                <switch-input class="my-auto" v-model="currentSubUserDetail.is_active" :value="currentSubUserDetail.is_active" />
              </div>

              <div class="input-group" style="gap: 20px;">
                <label class="my-auto" for="is_global_user">{{ 'Global User' }}
                  <!-- <span class="ml-1 text text-danger">*</span> -->
                </label>
                <switch-input class="my-auto" v-model="currentSubUserDetail.is_global_user" :value="currentSubUserDetail.is_global_user" />
              </div>
            </div>
    
            <div style="gap: 12px;" class="d-flex justify-content-end">
              <b-btn variant="primary" @click="hideAddUserModal" class="bg-white text-dark cancel border-0">Cancel</b-btn>
              <b-btn type="submit" variant="light" class="save">Save</b-btn>
            </div>
          </form>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
::v-deep .table-custom .table > thead > tr {
    th:first-child, th:last-child {
        border-radius: 0;
    }

    .table-b-table-default {
        border-bottom: 1px solid #DCDAD5;
        background: #e9e7e3 !important;
        font-weight: 350;
        line-height: 20px;
    }
}
.section-header {
    height: auto;
    padding: 25px 25px 15px;
}
.modal-close {
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: rgba(220, 218, 213, 0.30);
  }
}

::v-deep .modal-body {
  padding: 35px;
}
.form-group {
  margin-bottom: 25px;

  label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 15px !important;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 325;
    line-height: 24px;
  }
}

.form-control {
  height: 44px;
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.input-group {
  margin-bottom: 25px;
  width: auto;
}
</style>
