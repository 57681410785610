<script>
import switchInput from '../../../components/switch-input.vue';
import { url } from "vuelidate/lib/validators";
import {
    FETCH_MERCHANT_ENTITY_DETAILS,
    UPDATE_MERCHANT_ENTITY_DETAILS
} from "@/state/actions.type";

export default {
  components: { switchInput },
  page: {
    title: "Advanced Settings",
  },

  validations: {
    toggleItems: {
        merchant_confirmation_url: { url }
    }
  },

  data() {
    return {
        toggleItems: {
            master_merchant_access: false,
            verify_payouts: false,
            dynamic_currency_checkout_enabled: false,
            customer_vault_enabled: false,
            // dynamic_refund_rates_enabled: false,
            merchant_confirmation_url: "",
            payment_confirmation_service: false
        }
    }
  },

  created() {
    this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`).then(() => {
        this.toggleItems = {
            master_merchant_access: this.entityDetails.master_merchant_access,
            verify_payouts: this.entityDetails.isVerifyPayoutsEnabled,
            dynamic_currency_checkout_enabled: this.entityDetails.dynamic_currency_checkout_enabled,
            customer_vault_enabled: !this.entityDetails.customer_vault_enabled,
            // dynamic_refund_rates_enabled: this.entityDetails.dynamic_refund_rates_enabled,
            merchant_confirmation_url: this.entityDetails.merchant_confirmation_url,
            payment_confirmation_service: !!this.entityDetails.merchant_confirmation_url
        };
    });
  },

  computed: {
    entityDetails() {
      return this.$store.state.merchant.entityDetails;
    },
  },

  methods: {
    updateToggle(key) {
        if(this.entityDetails[key] !== this.toggleItems[key] || (key === 'verify_payouts' && this.entityDetails.isVerifyPayoutsEnabled !== this.toggleItems[key])) {
            const payload = {};
            payload[key] = this.toggleItems[key];

            this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, payload).then(() => {
              this.$notify({
                type: 'success',
                text: 'Value Updated Successfully',
                closeOnClick: true,
              });
            })
            .catch((error) => {
                // error
              console.log("error", error);
            });
        }
    },
    toggleMerchantCentricValt() {
        this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, {
            customer_vault_enabled: !this.toggleItems.customer_vault_enabled
        }).then(() => {
            this.$notify({
            type: 'success',
            text: 'Value Updated Successfully',
            closeOnClick: true,
            });
        })
        .catch((error) => {
            // error
            console.log("error", error);
        });
    },
    togglePaymentConfirmationService(value) {
        if(!value) {
            this.toggleItems.merchant_confirmation_url = "";
            this.updateToggle('merchant_confirmation_url');
        }
    },
    savePaymentConfirmation() {
        this.$v.$touch();

        if (this.$v.$invalid) {
            return false;
        }
        this.updateToggle('merchant_confirmation_url')
    },
    validateState(name) {
        const { $dirty, $error } = this.$v.toggleItems[name];
        return $dirty ? !$error : null;
    },
  }
};
</script>

<template>
  <div>
    <div class="main-section">
      <div class="section-header">
        <p class="section-title">Advanced Settings</p>
        <p class="section-text">Advanced features for advanced usecases.</p>
      </div>
    </div>
    <div style="width: fit-content;">
        <div class="toggle-settings" v-if="entityDetails.is_sub_merchant">
            <div class="d-flex" style="gap: 15px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11.74 17.7494H17.66C17.57 17.8294 17.48 17.8994 17.39 17.9794L13.12 21.1794C11.71 22.2294 9.41001 22.2294 7.99001 21.1794L3.71001 17.9794C2.77001 17.2794 2 15.7294 2 14.5594V7.14937C2 5.92937 2.93001 4.57937 4.07001 4.14937L9.05 2.27938C9.87 1.96937 11.23 1.96937 12.05 2.27938L17.02 4.14937C17.97 4.50937 18.78 5.50938 19.03 6.52938H11.73C11.51 6.52938 11.31 6.53938 11.12 6.53938C9.27 6.64938 8.78999 7.31937 8.78999 9.42937V14.8594C8.79999 17.1594 9.39001 17.7494 11.74 17.7494Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.79999 11.2188H22" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 9.41977V14.9698C21.98 17.1898 21.37 17.7397 19.06 17.7397H11.74C9.38999 17.7397 8.79999 17.1498 8.79999 14.8398V9.40976C8.79999 7.30976 9.27999 6.63974 11.13 6.51974C11.32 6.51974 11.52 6.50977 11.74 6.50977H19.06C21.41 6.51977 22 7.09977 22 9.41977Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.32 15.2598H12.65" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.75 15.2598H18.02" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="my-auto flex-fill">
                    Give Access to Master Merchant
                </p>
                <switch-input v-model="toggleItems.master_merchant_access" @input="updateToggle('master_merchant_access')" :value="toggleItems.master_merchant_access" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 717 2" style="margin-top: 15px; margin-bottom: 15px;" fill="none">
                <path d="M717 1L0 1" stroke="#DCDAD5" stroke-opacity="0.3"/>
            </svg>
            <p class="m-0 section-text font-16px">
                Control access to your account by your Master Merchant.
            </p>
        </div>
        <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M2 11V9C2 5.5 4 4 7 4H17C20 4 22 5.5 22 9V15C22 18.5 20 20 17 20H12" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 9.5V14.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 15.5H7.34003C7.98003 15.5 8.5 16.02 8.5 16.66V17.94" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.21997 14.2793L2 15.4993L3.21997 16.7192" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5 20.7798H3.15997C2.51997 20.7798 2 20.2598 2 19.6198V18.3398" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.28125 21.9986L8.50122 20.7786L7.28125 19.5586" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="my-auto flex-fill">
                    Payout Verification
                </p>
                <switch-input v-model="toggleItems.verify_payouts" @input="updateToggle('verify_payouts')" :value="toggleItems.verify_payouts" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 717 2" style="margin-top: 15px; margin-bottom: 15px;" fill="none">
                <path d="M717 1L0 1" stroke="#DCDAD5" stroke-opacity="0.3"/>
            </svg>
            <p class="m-0 section-text font-16px">
                Add an additional layer of safety to your Payouts.
            </p>
        </div>

        <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M2 11V9C2 5.5 4 4 7 4H17C20 4 22 5.5 22 9V15C22 18.5 20 20 17 20H12" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 9.5V14.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 15.5H7.34003C7.98003 15.5 8.5 16.02 8.5 16.66V17.94" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.21997 14.2793L2 15.4993L3.21997 16.7192" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5 20.7798H3.15997C2.51997 20.7798 2 20.2598 2 19.6198V18.3398" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.28125 21.9986L8.50122 20.7786L7.28125 19.5586" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="my-auto flex-fill">
                    Merchant Centric Vault
                </p>
                <switch-input v-model="toggleItems.customer_vault_enabled" @input="toggleMerchantCentricValt()" :value="toggleItems.customer_vault_enabled" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 717 2" style="margin-top: 15px; margin-bottom: 15px;" fill="none">
                <path d="M717 1L0 1" stroke="#DCDAD5" stroke-opacity="0.3"/>
            </svg>
            <p class="m-0 section-text font-16px">
                Securely save cards of your customers for auto-charging and one-click checkout experience.
            </p>
        </div>

        <!-- <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.02 4.46997L12 2" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.91 7.80072C3.8 9.28072 3.10999 11.1107 3.10999 13.1107C3.10999 18.0207 7.09 22.0007 12 22.0007C16.91 22.0007 20.89 18.0207 20.89 13.1107C20.89 8.20072 16.91 4.2207 12 4.2207C11.32 4.2207 10.66 4.31075 10.02 4.46075" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 14.9422C9 16.0172 9.825 16.8839 10.85 16.8839H12.9417C13.8333 16.8839 14.5583 16.1255 14.5583 15.1922C14.5583 14.1755 14.1167 13.8172 13.4583 13.5839L10.1 12.4172C9.44167 12.1839 9 11.8255 9 10.8089C9 9.87552 9.725 9.11719 10.6167 9.11719H12.7083C13.7333 9.11719 14.5583 9.98385 14.5583 11.0589" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.7734 8V18" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="my-auto flex-fill">
                    Dynamic Currency Conversion Refund
                </p>
                <switch-input v-model="toggleItems.dynamic_refund_rates_enabled" @input="updateToggle('dynamic_refund_rates_enabled')" :value="toggleItems.dynamic_refund_rates_enabled" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 717 2" style="margin-top: 15px; margin-bottom: 15px;" fill="none">
                <path d="M717 1L0 1" stroke="#DCDAD5" stroke-opacity="0.3"/>
            </svg>
            <p class="m-0 section-text font-16px">
               Enable to use latest exchange rates when initiating refunds for cross-border transactions
            </p>
        </div> -->

        <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 8.5H13.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 16.5H8" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 16.5H14.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 11.03V16.11C22 19.62 21.11 20.5 17.56 20.5H6.44C2.89 20.5 2 19.62 2 16.11V7.89C2 4.38 2.89 3.5 6.44 3.5H13.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.5 6L18 7.5L22 3.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="my-auto flex-fill">
                    Payment Confirmation Service
                </p>
                <switch-input v-model="toggleItems.payment_confirmation_service" @input="togglePaymentConfirmationService" :value="toggleItems.payment_confirmation_service" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 717 2" style="margin-top: 15px; margin-bottom: 15px;" fill="none">
                <path d="M717 1L0 1" stroke="#DCDAD5" stroke-opacity="0.3"/>
            </svg>
            <p class="m-0 section-text font-16px">
                Enable payment confirmation service for others. <a href="https://developers.portone.io/docs/en/tips/confirm-process?v=v2" target="_blank" class="text-chai">Learn More</a>
            </p>

            <div v-if="toggleItems.payment_confirmation_service">
                <div class="d-flex mt-4" style="gap: 16px;">
                    <b-input
                        :state="validateState('merchant_confirmation_url')"
                        type="url"
                        class="flex-fill"
                        v-model="toggleItems.merchant_confirmation_url"
                        placeholder="https://example.com"
                        style="border-radius: 12px; border: 1px solid #DCDAD5; background: #FFF; height: 44px;"
                    />
                    <b-btn variant="primary" style="padding: 12px 24px;" @click="savePaymentConfirmation">Save</b-btn>
                </div>
                <div
                    v-if="$v.toggleItems.merchant_confirmation_url.$error"
                    class="font-12px mt-1" style="color: #ff3d60;"
                >
                    please enter valid URL
                </div>
                <p class="mb-0 mt-3 section-text font-16px">
                    <span class="text-chai">Note:</span> After enabling all your transactions will have to be confirmed by you. 
                </p>
            </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toggle-settings {
    margin-top: 25px;
    padding: 25px;
    border-radius: 8px;
    border: 1px solid #DCDAD5;
}
</style>
