<script>
import Layout from "../../layouts/main";
import Account from './account.vue';
import AdvancedSettings from './advanced-settings.vue';
import DccSettings from './dcc-settings.vue';
import Api from './api.vue';
import Notifications from './notifications.vue';
import Profile from './profile.vue';
import Team from './team.vue';
import Webhook from './webhook.vue';

export default {
    components: {
        Layout,
        Profile,
        Team,
        Api,
        Webhook,
        AdvancedSettings,
        Account,
        Notifications,
        DccSettings
    },
    data() {
        return {
            activeTab: "Profile",
            tabs: ['Profile', 'Account', 'Team', 'API', 'Webhooks', 'DCC Settings']
        }
    },
    computed: {
        isMasterMerchant() {
            return this.$store.state.auth.currentUser.is_master_merchant;
        },
        isOnboardingCompleted() {
            return this.$store.state.auth.currentUser.is_onboarding_completed;
        },
    },
    watch: {
        '$route.query.tab'() {
            if(this.$route.query.tab && this.tabs.includes(this.$route.query.tab)) {
                this.activeTab = this.$route.query.tab;
            }
        },
    },
    created() {
        if(!this.isMasterMerchant) {
            // add Notifications tab
            this.tabs.splice(3, 0, "Notifications");
            // add Advanced tab
            this.tabs.splice(6, 0, "Advanced");
        }

        if(this.$route.query.tab && this.tabs.includes(this.$route.query.tab)) {
            this.activeTab = this.$route.query.tab;
        }
    },
    methods: {
        tabClicked(tab) {
            this.activeTab = tab;
            this.$router.push({name: 'General Settings', query: {tab: tab}});
        }
    }
}
</script>

<template>
    <Layout>
        <div class="menu-content">
            <div class="tab-content-section">
                <b-btn variant="primary" v-for="(tab, index) in tabs" :key="index" @click="tabClicked(tab)" class="tab-button" :class="{'tab-active': activeTab === tab, 'disable-side-bar': !isOnboardingCompleted && tab == 'Team'}">{{ tab }}</b-btn>
            </div>
        </div>
        <Profile v-if="activeTab === 'Profile'" />
        <account v-if="activeTab === 'Account'" />
        <Team v-else-if="activeTab === 'Team' && isOnboardingCompleted" />
        <notifications v-if="activeTab === 'Notifications'" />
        <Api v-else-if="activeTab === 'API'" />
        <Webhook v-else-if="activeTab === 'Webhooks'" />
        <advanced-settings v-else-if="activeTab === 'Advanced' && !isMasterMerchant" />
        <dcc-settings v-else-if="activeTab === 'DCC Settings'" />
    </Layout>
</template>

<style>

</style>